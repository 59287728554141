<template>
    <OIframe class="h-100 w-100" :class="{'d-none':props.hidden}" :src="url" ref="viewerRef" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { OIframe } from 'o365-ui-components';
import { GlobalMessenger } from 'o365-modules';
import { userSession, API } from 'o365-modules';
import { alert as o365alert } from 'o365-vue-services'

export interface Props {
    fileName?: string,
    primKey?: string,
    viewName?: string,
    id?: number,
    annotWhereClause?: string,
    readOnly?: boolean,
    viewerOnly?: boolean
    returnBtnText?:string
    returnBtnURL?:string
    openBlob?:string
    loadReadOnlyComments?: boolean,
    hidden?:boolean
};

export interface FoxitAnnot {
    id: number,
    info: FoxitAnnotInfo,
}

export interface FoxitAnnotInfo {
    objectNumber: number,
    id: number,
    subject: string,
    text: string,
    title: string,
    type: string,
    name: string
}

const viewerRef = ref(null);
const update = ref(false);
var gDebounce;
var gInitialAnnotsImport = false;
var gAnnots;

const encodeObject = (pValue: any) => {
    const jsonString = JSON.stringify({
        fileName: props.fileName,
        primKey: props.primKey,
        viewName: props.viewName,
        id: props.id,
        readOnly: props.readOnly,
        viewerOnly: props.viewerOnly,
        returnBtnText: props.returnBtnText,
        returnBtnURL: props.returnBtnURL,
        openBlob: props.openBlob,
        time_stamp: Date.now()
    });
    const encodedString = btoa(encodeURIComponent(jsonString));
    return encodedString;
}

const createEmptyDataItem = () => {
    return {
        Page: null,
        Annot: null,
        AnnotID: null,
        Type: null,
        Document_ID: null,
        Comment: null,
        Exclude: null
    }
}

const props = defineProps<Props>();
const url = computed(() => { return `/nt/foxit7-nt?params=${encodeObject(props)}` })

const emit = defineEmits<{
    (e: 'annotationAdded', pData: FoxitAnnot[], pPdfViewer: any),
    (e: 'annotationUpdated', pData: FoxitAnnot[], pPdfViewer: any),
    (e: 'annotationRemoved', pData: FoxitAnnot[], pPdfViewer: any),
    (e:'annotationsLoaded')
}>();

GlobalMessenger.on(async (pPayload) => {
    if (pPayload === "PDFUI_CREATED") {
        const pdfui = viewerRef.value?.iframeRef.contentWindow.pdfui;
        if(typeof pdfui === 'undefined')
            return;
            
        const PDFViewCtrl = viewerRef.value?.iframeRef.contentWindow.PDFViewCtrl;
        const PDFViewer = await pdfui.getPDFViewer();
        const PDFCurrentDoc =  await pdfui.getCurrentPDFDoc();
        //const AnnotAuthMgr = PDFViewer.getAnnotAuthorityManager();
        const userName = await userSession.name;
        pdfui.setUserName(userName);

        if(!props.readOnly || props.loadReadOnlyComments){
            await insertAnnotationsToPDF(PDFCurrentDoc, await loadAnnotations(props.id))
            emit("annotationsLoaded")
        }

        if (!props.readOnly) {
            /* EVENTS */
            pdfui.eventEmitter.on(PDFViewCtrl.constants.ViewerEvents.annotationAdd, (data) => {
                PDFCurrentDoc.exportAnnotsToJSON(data).then((annotations) =>{
                    PDFCurrentDoc.getPageAnnots(annotations[0].page).then(() =>{
                        createAnnotation(props.id,annotations).then(() => {
                            loadAnnotations(props.id).then((annots) => {
                                insertAnnotationsToPDF(PDFCurrentDoc,annots)
                            })
                        })
                    })
                    .catch((err) => {
                        o365alert("Failed to insert annotation into this page.")
                    })
                })
                .catch((err) => {
                    alert("Could not insert annotation...")
                })
            })

            pdfui.eventEmitter.on(PDFViewCtrl.constants.ViewerEvents.annotationUpdated, (data) => {
                if(update.value == true){
                    return;
                }
                if(gDebounce !== null){
                    clearTimeout(gDebounce)
                }
                
                gDebounce = setTimeout(function () {
                    PDFCurrentDoc.exportAnnotsToJSON(data).then((annotations) =>{
                        updateAnnotation(annotations);
                    })
                }, 500)
            })

            pdfui.eventEmitter.on(PDFViewCtrl.constants.ViewerEvents.annotationRemoved, (data) => {
                data.map(function(ele){
                    deleteAnnotation(ele.exportToJSON().name)
                })
            })

            pdfui.eventEmitter.on(PDFViewCtrl.constants.ViewerEvents.annotationImported, (doc, annots) => {
                if(!gInitialAnnotsImport){
                    gInitialAnnotsImport = true;
                    return;
                }
                createImportedAnnotations(annots, PDFCurrentDoc);
            })
        }
    }
})

function loadAnnotations(id){
    return new Promise(function(resolve, reject) {
        resolve(retrieveAnnotations(id));
    })
}


function insertAnnotationsToPDF(pdfDoc,data){
    gAnnots = data;
    return new Promise(function(resolve, reject) {
        resolve(
            async function(){
                let annots = data.map(row => row["Annot"] ? JSON.parse(row["Annot"])[0] : null).filter(row => row != null);
                gInitialAnnotsImport = false;
                pdfDoc.importAnnotsFromJSON(annots)
            }()
        );
    })
}

function isValidJSON(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false; 
    }
}

function createImportedAnnotations(pages, PDFCurrentDoc){
    let vNewAnnots = ''; //imported annots
    let vNonExistantAnnots = '' //annotations that exist in pdf but not in DB
    let vAnnotsJsons = [];

    pages.map(page => {
        page.newAnnots.map((annot) => {
            let annotInfo = annot.exportToJSON()
            if(annotInfo.name && annotInfo.name !== undefined && annotInfo.type !== 'popup' && annotInfo.type !== 'link') {
                vNewAnnots += annotInfo.name + ',';
                vAnnotsJsons.push(annotInfo);
            }
        })
    })
    if(vNewAnnots !== ''){
        annotExists(props.id,vNewAnnots.slice(0, -1)).then(async (data) => {
            vNonExistantAnnots += data.length > 0 ? data : "";
            vAnnotsJsons = vAnnotsJsons.filter((annot) => { return vNonExistantAnnots.includes(annot.name); })

            //to be removed once Aspose fix the Annotation ID uniquess across pages 
            let timestamp = Date.now();
            vAnnotsJsons.forEach(annot => {
                annot.name = `${annot.name}_${timestamp + 1}`;
                timestamp++; 
            });

            const asyncOperations = vAnnotsJsons.map(async(annot) => {
                await createAnnotation(props.id,[annot])
            })

            await Promise.all(asyncOperations)
        })
        .finally(() => {
            loadAnnotations(props.id).then((annots) => {
                insertAnnotationsToPDF(PDFCurrentDoc,annots)
            })
        })
    }
}

async function createAnnotation(id:number,annot:any){
    return API.requestPost(`/api/data`,
                            JSON.stringify({
                                operation:"create",
                                viewName:"atbv_Arena_DocumentsAnnotations",
                                values:
                                    {
                                        Annot:JSON.stringify(annot),
                                        Page:annot[0]["page"],
                                        AnnotID:annot[0]["name"],
                                        Document_ID:id,
                                        Comment:annot[0]["contents"]
                                    },
                                fields:[{name:"ID"},{name:"Page"},{name:"Annot"},{name:"AnnotID"},{name:"Type"},{name:"Exclude"}]
                            }));
}


async function retrieveAnnotations(document_ID:number){
    return API.requestPost(`/api/data`,
                            JSON.stringify({
                                operation:"retrieve",
                                viewName:"aviw_Arena_DocumentsAnnotations",
                                whereClause:"Document_ID = '"+document_ID+"'" + (props.annotWhereClause ? "AND " + props.annotWhereClause : ""),
                                fields:[{name:"ID"},{name:"Page"},{name:"Annot"},{name:"AnnotID"},{name:"Type"},{name:"Exclude"},{name:"CreatedBy"},{name:"UpdatedBy"}]
                            }))
}

async function updateAnnotation(annot:any){
    getAnnotationPrimKey(annot[0]["name"]).then((annotInfo) => {
        return API.requestPost(`/api/data`,
                                JSON.stringify({
                                    operation:"update",
                                    viewName:"atbv_Arena_DocumentsAnnotations",
                                    values:
                                        {
                                            Annot:JSON.stringify(annot),
                                            Page:annot[0]["page"],
                                            PrimKey:annotInfo[0]["PrimKey"],
                                            Comment:annot[0]["contents"]
                                        },
                                    fields:[{name:"ID"},{name:"Page"},{name:"Annot"},{name:"AnnotID"},{name:"Type"},{name:"Exclude"}]
                                }));
    })
}

async function deleteAnnotation(annotID:string){
    getAnnotationPrimKey(annotID).then((annotInfo) => {
        if(annotInfo.length == 0)
            return null;
        else
            return API.requestPost(`/api/data`,
                                    JSON.stringify({
                                        operation:"destroy",
                                        viewName:"atbv_Arena_DocumentsAnnotations",
                                        values:
                                            {
                                                PrimKey:annotInfo[0]["PrimKey"]
                                            },
                                        UniqueTable: 'atbl_Arena_DocumentsAnnotations'

                                    }));
        })
    }

async function getAnnotationPrimKey(annotID:string){
    return API.requestPost(`/api/data`,
                            JSON.stringify({
                                operation:"retrieve",
                                viewName:"aviw_Arena_DocumentsAnnotations",
                                whereClause:"AnnotID = '" + annotID + "' AND Document_ID = " + props.id,
                                fields:[{name:"PrimKey"},{name:"CreatedBy"},{name:"UpdatedBy"}]
                            }))
}

async function annotExists(id:number,annotIDs:string){
    return fetch('/api/arena/reviewHandler/checkAnnots', {
        method: "POST",
        body: JSON.stringify({
            document_id: id,
            annots: annotIDs
        }),
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        })
    }).then(response => response.json())
}

async function exportFdf(annots){
    const pdfui = viewerRef.value?.iframeRef.contentWindow.pdfui
    const PDFCurrentDoc =  await pdfui.getCurrentPDFDoc();
    return PDFCurrentDoc.exportAnnotsToFDF(0,annots);
}

async function importFdf(fdf){
    const pdfui = viewerRef.value?.iframeRef.contentWindow.pdfui;
    const PDFCurrentDoc = await pdfui.getCurrentPDFDoc();
    return PDFCurrentDoc.importAnnotsFromFDF(fdf, true);
}

async function exportAnnotations(){
    const pdfui = viewerRef.value?.iframeRef.contentWindow.pdfui
    const PDFCurrentDoc =  await pdfui.getCurrentPDFDoc();
    return PDFCurrentDoc.getAnnots();
}

async function getCurrentDoc(){
    const pdfui = viewerRef.value?.iframeRef.contentWindow.pdfui
    return await pdfui.getCurrentPDFDoc();
}

function skipUpdate(skip){
    update.value = skip;
}

defineExpose({
    viewerRef,
    exportFdf,
    exportAnnotations,
    importFdf,
    getCurrentDoc,
    skipUpdate
})
</script>